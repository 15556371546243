import Vue from 'vue';
import App from './App.vue';
import router from "./router";
import store from "./store";
import VueCookies from "vue-cookies";
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import apiEndpoints from './assets/js/apiEndpoints.js';
import globalVariables from './assets/js/global.js';
import jQuery from "jquery";
import methods from './mixins.js';
import VueProgressBar from 'vue-progressbar';
import { ValidationObserver, ValidationProvider, extend, localize } from "vee-validate";
import en from "vee-validate/dist/locale/en.json";
import { min, required, email, max } from "vee-validate/dist/rules";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

// axios setup
import axios from "axios";
import VueAxios from 'vue-axios';

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.config.productionTip = false;
Vue.use(VueAxios, axios, VueCookies);
Vue.prototype.$api = apiEndpoints;
Vue.prototype.$apiHeaders = globalVariables.headers;
Vue.prototype.$applicationTitle = globalVariables.application_title;

const $ = jQuery;
window.$ = $;

import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faUserSecret,
    faHome,
    faEllipsisV,
    faBars,
    faCartPlus,
    faUsers,
    faCaretDown,
    faFile,
    faPen,
    faHistory,
    faTrashAlt,
    faPlus,
    faAngleDoubleRight,
    faAngleDoubleLeft,
    faSpinner,
    faTasks,
    faUser,
    faEye,
    faAngleDown,
    faTable,
    faPlayCircle,
    faThLarge,
    faCheck,
    faCaretLeft,
    faTimes,
    faRedo,
    faReply,
    faGripVertical,
    faCog,
    faArrowRight,
    faUndo,
    faDownload,
    faCheckCircle,
    faExclamationTriangle,
    faCalendarPlus,
    faCalendarAlt,
    faRupeeSign,
    faCircle,
    faBan,
    faCopy,
    faEyeSlash,
    faClipboardList
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(
    faUserSecret,
    faHome,
    faEllipsisV,
    faBars,
    faCartPlus,
    faUsers,
    faCaretDown,
    faFile,
    faPen,
    faHistory,
    faTrashAlt,
    faPlus,
    faAngleDoubleRight,
    faAngleDoubleLeft,
    faSpinner,
    faTasks,
    faUser,
    faEye,
    faAngleDown,
    faTable,
    faPlayCircle,
    faThLarge,
    faCheck,
    faCaretLeft,
    faTimes,
    faRedo,
    faReply,
    faGripVertical,
    faCog,
    faArrowRight,
    faUndo,
    faDownload,
    faCheckCircle,
    faExclamationTriangle,
    faCalendarPlus,
    faCalendarAlt,
    faRupeeSign,
    faCircle,
    faBan,
    faCopy,
    faEyeSlash,
    faClipboardList
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

// page not found default settings
const errorMessage = {
  code: [4, 0, 1],
  msg: ""
};

// Progress bar customization settings
const options = {
    color: '#007bff',
    failedColor: '#ff0000',
    thickness: '3px'
        // height: '2px',
        // transition: {
        //     speed: '0.2s',
        //     opacity: '0.6s',
        //     termination: 300
        // }
};
Vue.use(VueProgressBar, options);

// Form Validation Rules Set
extend("required", required);
extend("min", min);
extend("email", email);
extend("max", max);
// Install messages for Form Validation Rules
localize({
    en
});
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

// include global function with mixins
Vue.mixin({ methods });
Vue.mixin({
    methods: {
        hasPermission: function(permission) {
            const user = this.getUserData();
            const permit = user.user_details.permissions.filter(
                item => (item == permission)
            );
            if (permit.length > 0) {
                return true;
            } else {
                errorMessage.msg =
                    "Access Denied, Please contact Admin for more details.";
                return false;
            }
        },
        hasAnyPermission: function(permission) {
            const user = this.getUserData();
            var permit =  user.user_details.permissions.filter(
                    item => permission.includes(item)
                );
            if (permit.length > 0) {
                return true;
            } else {
                errorMessage.msg =
                    "Access Denied, Please contact Admin for more details.";
                return false;
            }
        },
        getErrorMessage: function() {
            return errorMessage;
        },
        setFileUrl: function(url) {
            if (url != null) {
                if (!url.startsWith('http')) {
                    if (url.startsWith('jobs')) {
                        url = this.$api.host_url + 'storage/' + url;
                    } else {
                        url = this.$api.host_url + url;
                    }
                } else if (url.includes("localhost")) {
                    let localhostUrl = location.host.split(':');
                    url = url.replace("localhost", localhostUrl[0]);
                }
            }
            return url;
        },
        getActiveTeamId: function() {
            if (!window.$cookies.isKey("user")) {
                return "";
            }
            const user = window.$cookies.get('user');
            let team_id = '';
            if(user.user_details.teams &&  user.user_details.teams != null && user.user_details.teams.length > 0){
                if(Object.prototype.hasOwnProperty.call(sessionStorage, 'user')) {
                    let user_session = JSON.parse(sessionStorage.getItem('user'));
                    team_id = user_session.team_id;
                } else {
                    team_id = user.user_details.teams[0].id;
                }
            }
            return team_id;
        },
        getRouteParams: function(param = null) {
            if (param === null){
                return this.$route.params;
            } else if (Object.prototype.hasOwnProperty.call(this.$route.params, param)){
                return this.$route.params[param];
            } else {
                return null;
            }
        },
        getRouteQueryParams: function(query = null) {
            if (query === null){
                return this.$route.query;
            } else if (Object.prototype.hasOwnProperty.call(this.$route.query, query)){
                return this.$route.query[query];
            } else {
                return null;
            }
        },
        alert: function(type, message, seconds = null, dismissable = null) {
            this.$emit('onAlert', {
                type: type,
                message: message,
                seconds: seconds,
                dismissable: dismissable
            });
        },
        str_title: function(str){
             if (str !== null) {
                str = str.replace(/_/g, " ");
                var str_array = str.toLowerCase().split(' '); 
                for (var i = 0; i < str_array.length; i++) { 
                    str_array[i] = str_array[i].charAt(0).toUpperCase() + str_array[i].slice(1);  
                } 
                return str_array.join(' ');
            }
        },
        strTitleCase: function(str){
            if (str !== null) {
                str = str.replace(/_/g, " ");
                var str_array = str.split(' '); 
                for (var i = 0; i < str_array.length; i++) { 
                    if (!/^[A-Z]*$/.test(str_array[i])) {
                        str_array[i] = str_array[i].toLowerCase(); 
                        str_array[i] = str_array[i].charAt(0).toUpperCase() + str_array[i].slice(1);
                    }
                } 
                return str_array.join(' ');
            }
            return str;
            
        },
        getUUID: function(){
			var dt = new Date().getTime();
			var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
				var r = (dt + Math.random()*16)%16 | 0;
				dt = Math.floor(dt/16);
				return (c=='x' ? r :(r&0x3|0x8)).toString(16);
			});
			return uuid;
        },
        secondsToTimeStamp(value) {
			const sec = parseFloat(value); // convert value to number if it's string
            return new Date(sec * 1000).toISOString().substr(11, 12);
		},
		timeStampToSeconds(string) {
			let splitted_array = string.split(':');
			return (+splitted_array[0]) * 60 * 60 + (+splitted_array[1]) * 60 + (+splitted_array[2])
        },
        notify(msg, variant = 'primary', title = 'Message', delay = 5000, position = 'top-center') {
            // Use a shorter name for this.$createElement
            const h = this.$createElement
                // Create the message
            const vNodesMsg = h(
                    'p', { class: ['text-center', 'mb-0'] }, [
                        // h('b-spinner', { props: { type: 'grow', small: true } }),
                        h('span', { class: 'mx-2' }, `${msg}`),
                        // h('b-spinner', { props: { type: 'grow', small: true } })
                    ]
                )
                // Create the title
            const vNodesTitle = h(
                    'div', { class: ['d-flex', 'flex-grow-1', 'align-items-baseline', 'mr-2'] }, [
                        h('strong', { class: 'mr-2' }, `${title}`),
                        // h('small', { class: 'ml-auto text-italics' }, 'Just Now')
                        h('small', { class: 'ml-auto text-italics' }, '')
                    ]
                )
                // Pass the VNodes as an array for message and title
            this.$bvToast.toast([vNodesMsg], {
                title: [vNodesTitle],
                solid: true,
                toaster: `b-toaster-${position}`,
                autoHideDelay: delay,
                variant: variant,
                appendToast: true
            })
        },
    }
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
