import Vue from "vue";
import Router from "vue-router";
import DefaultLayout from "./views/layouts/DefaultLayout.vue";

Vue.use(Router);

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes: [{
            path: "/",
            redirect: "/login"
        },
        {
            path: "/home",
            component: DefaultLayout,
            children: [{
                    path: "/home",
                    name: "home",
                    component: () =>
                        import ("./views/admin/Dashboard.vue")
                },
                {
                    path: "/users",
                    name: "users",
                    component: () =>
                        import ("./views/admin/users/Index.vue")
                },
                {
                    path: "/create-user",
                    name: "create-user",
                    component: () =>
                        import ("./views/admin/users/Add.vue")
                },
                {
                    path: "/edit-user/:id",
                    name: "edit-user",
                    component: () =>
                        import ("./views/admin/users/Edit.vue")
                },
                {
                    path: "/app-settings",
                    name: "app-settings",
                    component: () =>
                        import ("./views/admin/AppSettings.vue")
                },
                {
                    path: "/settings",
                    name: "settings",
                    component: () =>
                        import ("./views/user/Settings.vue")
                },
                {
                    path: "/admin/create-role",
                    name: "create-role",
                    component: () =>
                        import ("./views/admin/roles/Add.vue")
                },
                {
                    path: "/admin/edit-role/:role",
                    name: "edit-role",
                    component: () =>
                        import ("./views/admin/roles/Edit.vue")
                },
                {
                    path: "/admin/roles",
                    name: "roles",
                    component: () =>
                        import ("./views/admin/roles/Index.vue")
                },
                {
                    path: "/employees",
                    name: "employees",
                    component: () =>
                        import ("./views/pages/employees/Index.vue")
                },
                {
                    path: "/create-employee",
                    name: "create-employee",
                    component: () =>
                        import ("./views/pages/employees/Add.vue")
                },
                {
                    path: "/credit-debit-leaves/:emp_id",
                    name: "credit-debit-leaves",
                    component: () =>
                        import ("./views/pages/employees/CreditDebitLeaves.vue")
                },
                {
                    path: "/edit-employee/:id",
                    name: "edit-employee",
                    component: () =>
                        import ("./views/pages/employees/Edit.vue")
                },
                {
                    path: "/employee-history/:id",
                    name: "employee-history",
                    component: () =>
                        import ("./views/pages/employees/History.vue")
                },
                {
                    path: "/employee/details/:id",
                    name: "employee-details",
                    component: () =>
                        import ("./views/pages/employees/View.vue")
                },
                {
                    path: "/employee-attendance/:full_name/:employee_id",
                    name: "employee-attendance",
                    component: () =>
                        import ("./views/pages/employee-attendance/Index.vue")
                },
                {
                    path: "/create-employee-group",
                    name: "create-employee-group",
                    component: () =>
                        import ("./views/pages/employee-groups/Add.vue")
                },
                {
                    path: "/edit-employee-group/:id",
                    name: "edit-employee-group",
                    component: () =>
                        import ("./views/pages/employee-groups/Edit.vue")
                },
                {
                    path: "/employee-groups",
                    name: "employee-groups",
                    component: () =>
                        import ("./views/pages/employee-groups/Index.vue")
                },
                {
                    path: "/create-salary-slab",
                    name: "create-salary-slab",
                    component: () =>
                        import ("./views/pages/salary-slabs/Add.vue")
                },
                {
                    path: "/salary-slabs",
                    name: "salary-slabs",
                    component: () =>
                        import ("./views/pages/salary-slabs/Index.vue")
                },
                {
                    path: "/edit-salary-slab/:id",
                    name: "edit-salary-slab",
                    component: () =>
                        import ("./views/pages/salary-slabs/Edit.vue")
                },
                {
                    path: "/holiday-group/create",
                    name: "create-holiday-group",
                    component: () =>
                        import ("./views/pages/holiday-groups/Add.vue")
                },
                {
                    path: "/holiday-groups/",
                    name: "holiday-groups",
                    component: () =>
                        import ("./views/pages/holiday-groups/Index.vue")
                },
                {
                    path: "/edit-holiday-group/:id",
                    name: "edit-holiday-group",
                    component: () =>
                        import ("./views/pages/holiday-groups/Edit.vue")
                },
                {
                    path: "/leave-groups/create",
                    name: "create-leave-group",
                    component: () =>
                        import ("./views/pages/leave-groups/Add.vue")
                },
                {
                    path: "/leave-groups",
                    name: "leave-groups",
                    component: () =>
                        import ("./views/pages/leave-groups/Index.vue")
                },
                {
                    path: "/edit-leave-group/:id",
                    name: "edit-leave-group",
                    component: () =>
                        import ("./views/pages/leave-groups/Edit.vue")
                },
                {
                    path: "/admin/permissions",
                    name: "permissions",
                    component: () =>
                        import ("./views/admin/permissions/Index.vue")
                },
                {
                    path: "/admin/create-permissions",
                    name: "create-permissions",
                    component: () =>
                        import ("./views/admin/permissions/Add.vue")
                },
                {
                    path: "/holiday-groups/:holiday_group/holidays/create/",
                    name: "create-holiday",
                    component: () =>
                        import ("./views/pages/holidays/Add.vue")
                },
                {
                    path: "/holiday-groups/details/:holiday_group/holidays/",
                    name: "holidays",
                    component: () =>
                        import ("./views/pages/holidays/Index.vue")
                },
                {
                    path: "/holiday-groups/:holiday_group/edit-holiday/:id/",
                    name: "edit-holiday",
                    component: () =>
                        import ("./views/pages/holidays/Edit.vue")
                },
                {

                    path: "/working-shifts/create",
                    name: "create-working-shift",
                    component: () =>
                        import ("./views/pages/working-shifts/Add.vue")
                },
                {
                    path: "/working-shifts/",
                    name: "working-shifts",
                    component: () =>
                        import ("./views/pages/working-shifts/Index.vue")
                },
                {
                    path: "/edit-working-shift/:id",
                    name: "edit-working-shift",
                    component: () =>
                        import ("./views/pages/working-shifts/Edit.vue")
                },
                {
                    path: "/payroll-formulas/",
                    name: "payroll-formulas",
                    component: () =>
                        import ("./views/pages/earning-deductions/payroll-formulas/Index.vue")
                },
                {
                    path: "/create-payroll-formula/",
                    name: "create-payroll-formula",
                    component: () =>
                        import ("./views/pages/earning-deductions/payroll-formulas/Add.vue")
                },
                {
                    path: "/edit-payroll-formula/:id",
                    name: "edit-payroll-formula",
                    component: () =>
                        import ("./views/pages/earning-deductions/payroll-formulas/Edit.vue")
                },
                {
                    path: "/payroll/:emp_id",
                    name: "payroll",
                    component: () =>
                        import ("./views/pages/payroll/Index.vue")
                },
                {
                    path: "/payroll/:emp_id/details/:id",
                    name: "payroll-details",
                    component: () =>
                        import ("./views/pages/payroll/View.vue")
                },
                {
                    path: "/test-payroll/:emp_id",
                    name: "test-payroll",
                    component: () =>
                        import ("./views/pages/test-payroll/Index.vue")
                },
                {
                    path: "/test-payroll/:emp_id/details/:test_payroll_id",
                    name: "test-payroll-details",
                    component: () =>
                        import ("./views/pages/test-payroll/View.vue")
                },
                {
                
                    path: "/special-earnings",
                    name: "special-earnings",
                    component: () =>
                        import ("./views/pages/earning-deductions/special-earnings/Index.vue")
                },
                {
                    path: "/special-earnings/create/:type/:month/:year",
                    name: "create-special-earning",
                    component: () =>
                        import ("./views/pages/earning-deductions/special-earnings/Add.vue")
                },
                {
                    path: "/special-earnings/:type/:month/:year/:id",
                    name: "edit-special-earning",
                    component: () =>
                        import ("./views/pages/earning-deductions/special-earnings/Edit.vue")
                },
                {
                    path: "/default-earning-deductions",
                    name: "default-earning-deductions",
                    component: () =>
                        import ("./views/pages/earning-deductions/default-earning-deductions/Index.vue")
                },
                {
                    path: "/default-earning-deductions/create/:type",
                    name: "create-default-earning-deductions",
                    component: () =>
                        import ("./views/pages/earning-deductions/default-earning-deductions/Add.vue")
                },
                {
                    path: "/default-earning-deductions/edit/:type/:id",
                    name: "edit-default-earning-deductions",
                    component: () =>
                        import ("./views/pages/earning-deductions/default-earning-deductions/Edit.vue")
                },
                {
                    path: "/employee-earning-deductions",
                    name: "employee-earning-deductions",
                    component: () =>
                        import ("./views/pages/earning-deductions/employee-earning-deductions/Index.vue")
                },
                {
                    path: "/employee-earning-deductions/create/:type/:emp_id",
                    name: "create-employee-earning-deductions",
                    component: () =>
                        import ("./views/pages/earning-deductions/employee-earning-deductions/Add.vue")
                },
                {
                    path: "/employee-earning-deductions/edit/:type/:emp_id/:id",
                    name: "edit-employee-earning-deductions",
                    component: () =>
                        import ("./views/pages/earning-deductions/employee-earning-deductions/Edit.vue")
                },
                {
                    path: "/employee/:emp_id/salary-appraisal",
                    name: "employee-salary-appraisal",
                    component: () =>
                        import ("./views/pages/employee-salaries/Add.vue")
                },
                {
                    path: "/employee/:emp_id/salary-history",
                    name: "employee-salary-history",
                    component: () =>
                        import ("./views/pages/employee-salaries/SalaryHistory.vue")
                },
                {
                    path: "/employee/:emp_id/salary-correction",
                    name: "employee-salary-correction",
                    component: () =>
                        import ("./views/pages/employee-salaries/Edit.vue")
                },
                {
                    path: "/employee-salaries/create",
                    name: "create-employee-salary",
                    component: () =>
                        import ("./views/pages/employee-salaries/Add.vue")
                },
                {
                    path: "/employee-salaries",
                    name: "employee-salaries",
                    component: () =>
                        import ("./views/pages/employee-salaries/Index.vue")
                },
                {
                    path: "/edit-employee-salary/:id",
                    name: "edit-employee-salary",
                    component: () =>
                        import ("./views/pages/employee-salaries/Edit.vue")
                },
                {
                    path: "/earning-deductions",
                    name: "earning-deductions",
                    component: () =>
                        import ("./views/pages/earning-deductions/Index.vue")
                },
                {
                    path: "/group-earning-deductions",
                    name: "group-earning-deductions",
                    component: () =>
                        import ("./views/pages/earning-deductions/group-earning-deductions/Index.vue")
                },
                {
                    path: "/group-earning-deductions/create/:type/:employee_group_id",
                    name: "create-group-earning-deductions",
                    component: () =>
                        import ("./views/pages/earning-deductions/group-earning-deductions/Add.vue")
                },
                {
                    path: "/group-earning-deductions/edit/:type/:employee_group_id/:id",
                    name: "edit-group-earning-deductions",
                    component: () =>
                        import ("./views/pages/earning-deductions/group-earning-deductions/Edit.vue")
                },
                {
                    path: "/slab-earning-deductions",
                    name: "slab-earning-deductions",
                    component: () =>
                        import ("./views/pages/earning-deductions/slab-earning-deductions/Index.vue")
                },
                {
                    path: "/slab-earning-deductions/create/:type/:slab_id",
                    name: "create-slab-earning-deductions",
                    component: () =>
                        import ("./views/pages/earning-deductions/slab-earning-deductions/Add.vue")
                },
                {
                    path: "/slab-earning-deductions/edit/:type/:slab_id/:id",
                    name: "edit-slab-earning-deductions",
                    component: () =>
                        import ("./views/pages/earning-deductions/slab-earning-deductions/Edit.vue")
                },
                {
                    path: "/employees-payroll",
                    name: "employees-payroll",
                    component: () =>
                        import ("./views/pages/employees-payroll/Index.vue")
                },
                {
                    path: "/employees-payroll/:emp_id/details/:id",
                    name: "employees-payroll-details",
                    component: () =>
                        import ("./views/pages/employees-payroll/View.vue")
                },
                {
                    path: "/payroll-export",
                    name: "payroll-export",
                    component: () => 
                        import ("./views/pages/payroll-export/Index.vue")
                },
                {
                    path: "/download-exports",
                    name: "download-exports",
                    component: () => 
                        import ("./views/pages/payroll-export/DownloadExports.vue")
                },
                {
                    path: "/error-logs",
                    name: "error-logs",
                    component: () => 
                        import ("./views/pages/error-logs/Index.vue")
                }
            ]
        },
        {
            path: "/login",
            name: "login",
            component: () =>
                import ("./views/auth/Login.vue")
        },
        {
            path: "/404",
            name: "404",
            component: () =>
                import ("./views/errors/NotFound.vue")
        },
        {
            path: "*",
            redirect: "/404"
        }
    ]
});
router.beforeEach((to, from, next) => {
    document.title = 'Infoesearch HRMS';
    const publicPages = ["/login", "/404"];
    const authRequired = !publicPages.includes(to.path);

    if (authRequired) {
        if (window.$cookies.isKey("user")) {
            const user = window.$cookies.get("user");
            if (user.access_token != (null || undefined)) {
                const permit = user.user_details.permissions.filter(
                    item => (item == 'CAN_ACCESS_HRMS_UI')
                );
                if(permit.length == 0) {
                    next('/404')
                }
                next();
            } else {
                return next("/login");
            }
        } else {
            return next("/login");
        }
    }
    next();
});
export default router;