<template>
    <div id="app">
            <div v-if="api_error" class="container-fluid mt-5">
                <b-alert
                    :show="alertDismissCountDown"
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <div v-if="app_settings_ready">
                <router-view />
            </div>
            <div class="row justify-content-center" v-else>
                <div class="col-sm-5 text-center pt-2">
                    <font-awesome-icon icon="spinner" spin size="3x"></font-awesome-icon>
                    <p class="text-center my-1">Loading...</p>
                </div>
            </div>
            <vue-progress-bar></vue-progress-bar>
    </div>
</template>
<script>
export default {
    data() {
        return {
            alertDismissCountDown: 0,
            alertDismissSecs: 1000,
            api_error:""
        }
    },
    mounted() {
        this.getAppSettings();
    },
    computed:{
        app_settings_ready() {
            let app_settings = this.$store.getters.getAppSetting();
            if (Object.keys(app_settings).length === 0){
                return false;
            } else {
                return true;
            }
        }
    },
    methods: {
        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },
        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },
        getAppSettings: function() {
            this.axios
                .get(this.$api.get_app_settings, this.$apiHeaders)
                .then(response => {
                    let app_settings_data = response.data;
                    this.$store.commit('setAppSettings', app_settings_data);
                })
                .catch(err => {
                    console.error(err);
                    this.api_error = "Unable to load App Settings. Please contact Administrator."
                    this.showAlert();
                });
        }
    }
}
</script>