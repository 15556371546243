import api from "../../../public/config.js";
// split host url and port from domain if localhost exists
const host = location.host.split(':');
let apiBaseUrl = (api.apiBaseUrl.includes("localhost")) ? api.apiBaseUrl.replace("localhost", host[0]) : api.apiBaseUrl;

// use original https protocol if in production
const protocol = location.protocol;
apiBaseUrl = apiBaseUrl.replace("http:", protocol);

const baseUrl = apiBaseUrl + 'api/';
const apiEndpoints = {
    host_url: apiBaseUrl,
    login: baseUrl + "login",
    create_user: baseUrl + "users/create",
    get_users: baseUrl + "users",
    update_user: baseUrl + "users/update",
    delete_user: baseUrl + "users/delete",
    get_roles: baseUrl + "roles",
    create_role: baseUrl + "roles/create",
    update_role: baseUrl + "roles/update",
    delete_role: baseUrl + "roles/delete",
    get_app_settings: baseUrl + "app-settings",
    get_app_setting_with_fields: baseUrl + "app-settings/with-fields",
    update_app_settings: baseUrl + "app-settings/update",
    get_user_setting_with_fields: baseUrl + "user-settings/with-fields",
    update_user_settings: baseUrl + "user-settings/update",
    change_password: baseUrl + "users/change-password",
    update_profile: baseUrl + "users/update-profile",
    get_employees: baseUrl + "employees",
    get_suggested_id: baseUrl + "employees/get-suggested-id",
    get_employees_list: baseUrl + "employees/list",
    create_employee: baseUrl + "employees/create",
    update_employee: baseUrl + "employees/update",
    get_employee_groups: baseUrl + 'employee-groups',
    credit_debit_leaves: baseUrl + "leave-balance/credit-debit-leaves",
    get_remaining_leaves: baseUrl + "leave-balance/get-remaining-leaves",
    get_employee_attendance: baseUrl + 'employee-attendance',
    create_employee_attendance: baseUrl + 'employee-attendance/create',
    update_employee_attendance: baseUrl + 'employee-attendance/update',
    create_employee_group: baseUrl + "employee-groups/create",
    update_employee_group: baseUrl + "employee-groups/update",
    get_salary_slabs: baseUrl + "salary-slabs",
    get_delete_salary_slabs: baseUrl + "salary-slabs/deleted-slabs",
    create_salary_slab: baseUrl + "salary-slabs/create",
    update_salary_slab: baseUrl + "salary-slabs/update",
    delete_salary_slab: baseUrl + "salary-slabs/delete",
    get_holiday_groups: baseUrl + "holiday-groups",
    create_holiday_group: baseUrl + "holiday-groups/create",
    update_holiday_group: baseUrl + "holiday-groups/update",
    get_leave_groups: baseUrl + "leave-groups",
    create_leave_group: baseUrl + "leave-groups/create",
    update_leave_group: baseUrl + "leave-groups/update",
    delete_leave_group: baseUrl + "leave-groups/delete",
    get_leave_groups_list: baseUrl + "leave-groups/list",
    get_permissions: baseUrl+ "permissions",
    create_permissions: baseUrl + "permissions/create",
    get_holiday_group_list: baseUrl + "holiday-groups/list",
    get_holidays: baseUrl + "holidays",
    delete_holiday: baseUrl + "holidays/delete",
    create_holiday: baseUrl + "holidays/create",
    update_holiday: baseUrl + "holidays/update",
    get_working_shift_list: baseUrl + "working-shifts/list",
    get_working_shifts: baseUrl + "working-shifts",
    delete_working_shift: baseUrl + "working-shifts/delete",
    create_working_shift: baseUrl + "working-shifts/create",
    update_working_shift: baseUrl + "working-shifts/update",
    get_payroll_formulas: baseUrl + "payroll-formulas",
    create_payroll_formulas: baseUrl + "payroll-formulas/create",
    update_payroll_formulas: baseUrl + "payroll-formulas/update",
    get_payroll: baseUrl + "employee-payroll",
    get_test_employee_payroll: baseUrl + "test-employee-payroll",
    generate_test_payroll: baseUrl + "test-employee-payroll/generate-test-payroll",
    get_special_groups: baseUrl + "special-earning",
    create_special_group: baseUrl + "special-earning/create",
    update_special_group: baseUrl + "special-earning/update",
    delete_special_group: baseUrl + "special-earning/delete",
    update_status_special_group: baseUrl + "special-earning/status",
    get_employee_salaries_by_slab: baseUrl + "employee-salary/get-employee-salaries",
    get_default_earning_deductions: baseUrl + "default-earning-deductions",
    create_default_earning_deduction: baseUrl + "default-earning-deductions/create",
    update_default_earning_deduction: baseUrl + "default-earning-deductions/update",
    delete_default_earning_deduction: baseUrl + "default-earning-deductions/delete",
    update_status_default_earning_deduction: baseUrl + "default-earning-deductions/status",
    unused_formula_default_earning_deduction: baseUrl + "default-earning-deductions/unused-formula",
    get_employee_salaries: baseUrl + "employee-salary",
    get_my_salary: baseUrl + "employee-salary/get-my-salary",
    create_employee_salary: baseUrl + "employee-salary/create",
    update_employee_salary: baseUrl + "employee-salary/update",
    delete_employee_salary: baseUrl + "employee-salary/delete",
    get_employee_earning_deductions: baseUrl + "employee-earning-deductions",
    create_employee_earning_deduction: baseUrl + "employee-earning-deductions/create",
    update_employee_earning_deduction: baseUrl + "employee-earning-deductions/update",
    delete_employee_earning_deduction: baseUrl + "employee-earning-deductions/delete",
    update_status_employee_earning_deduction: baseUrl + "employee-earning-deductions/status",
    unused_formula_employee_earning_deduction: baseUrl + "employee-earning-deductions/unused-formula",
    get_group_earning_deductions: baseUrl + "group-earning-deductions",
    create_group_earning_deduction: baseUrl + "group-earning-deductions/create",
    update_group_earning_deduction: baseUrl + "group-earning-deductions/update",
    delete_group_earning_deduction: baseUrl + "group-earning-deductions/delete",
    update_status_group_earning_deduction: baseUrl + "group-earning-deductions/status",
    unused_formula_group_earning_deduction: baseUrl + "group-earning-deductions/unused-formula",
    get_slab_earning_deductions: baseUrl + "slab-earning-deductions",
    create_slab_earning_deduction: baseUrl + "slab-earning-deductions/create",
    update_slab_earning_deduction: baseUrl + "slab-earning-deductions/update",
    delete_slab_earning_deduction: baseUrl + "slab-earning-deductions/delete",
    update_status_slab_earning_deduction: baseUrl + "slab-earning-deductions/status",
    unused_formula_slab_earning_deduction: baseUrl + "slab-earning-deductions/unused-formula",
    get_all_payroll: baseUrl + "employee-payroll/get-all",
    download_payroll: baseUrl + "employee-payroll/download-payroll",
    export_payroll: baseUrl + "employee-payroll/export",
    get_generated_payrolls: baseUrl + "employee-payroll/generate-payrolls",
    payroll_exports: baseUrl + "payroll-exports",
    download_file: baseUrl + "payroll-exports/download-file",
    get_search_employee: baseUrl + "employees/search-employees",
    get_error_logs: baseUrl + "error-logs",
}

export default apiEndpoints;
