import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        app_settings:{},
    },
    getters: {
        getAppSetting: (state) => (group_name = null, setting_name = null, default_value = null) => {
            if (group_name === null) {
                return state.app_settings
            } else if (group_name != null && setting_name === null){
                if (Object.prototype.hasOwnProperty.call(state.app_settings, group_name)){
                    return state.app_settings.group_name;
                } else {
                    return null;
                }
            } else if (group_name != null && setting_name != null) {
                if (Object.prototype.hasOwnProperty.call(state.app_settings, group_name)) {
                    let app_setting = state.app_settings[group_name].find(({ name }) => name == setting_name)
                    if (app_setting) {
                        if (app_setting.app_settings_fields.type == "json" && app_setting.value) {
                            return JSON.parse(app_setting.value);
                        } else {
                            return app_setting.value;
                        }
                    } else if (default_value !== null) {
                        return default_value;
                    } else {
                        return null;
                    }
                } else if (default_value !== null) {
                    return default_value;
                }
            }
        }
    },
    mutations: {
        setAppSettings(state, app_settings) {
            state.app_settings = app_settings;
        }
    },
    actions: {}
});
